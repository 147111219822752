import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../../services/message.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { finalize } from 'rxjs/operators';
import { NotasFiscaisService } from '../../../services/notas-fiscais.service';

@Component({
  selector: 'app-editar-cfop-nota-saida',
  templateUrl: './editar-cfop-nota-saida.component.html',
  styleUrls: ['./editar-cfop-nota-saida.component.scss']
})
export class EditarCfopNotaSaidaComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  @Input() clienteCnpj = '';
  @Input() clienteNome = '';
  @Input() numeroNotaFiscalSaida = null;
  @Input() notaFiscalSaidaID = null;

  dados = {
    cfopsNotaSaida: [],
    cfopsDisponiveis: []
  };

  constructor(
    private messageService: MessageService,
    private notasFiscaiservice: NotasFiscaisService
  ) {
    super();
  }

  ngOnInit() {
    this.clienteCnpj = this.prop.clienteCnpj;
    this.clienteNome = this.prop.clienteNome;
    this.numeroNotaFiscalSaida = this.prop.numeroNotaFiscalSaida;
    this.notaFiscalSaidaID = this.prop.notaFiscalSaidaID;

    this.loading['cfop'] = true;
    this.notasFiscaiservice.getDadosEdicaoCfopNota(this.notaFiscalSaidaID)
      .subscribe(data => {
        this.dados = data;
        this.loading['cfop'] = false;
      }, error => {
        this.messageService.error(error.msg.errors[0], 15000);
      });
  }

  show() {
    this.modalCadastro.show();
  }

  destroyComponent() {
    this.closed.emit();
  }

  buscaCfop(term: string, item: any): boolean {
    const desc = item.codigo + " - " + item.descricao;
    return desc.toLowerCase().includes(term.toLowerCase());
  }

  salvar() {
    this.loading['salvar'] = true;
    const dados = {
      cfopsAlteradas: this.dados.cfopsNotaSaida.filter(i => i.novoCodigo),
    };

    this.notasFiscaiservice
      .editarCfopNotaSaida(this.notaFiscalSaidaID, dados)
      .pipe(finalize(() => {
        this.loading['salvar'] = false;
      }))
      .subscribe(() => {
        this.messageService.success("Nota alterada com sucesso!", 5000);
        this.closed.emit({ editou: true });
      }, error => {
        this.messageService.error(error.msg.errors[0], 15000);
      });
  }

  get alterouCfop() {
    return this.dados.cfopsNotaSaida.some(i =>
      i.novoCodigo && i.novoCodigo.toString() !== i.codigo.toString());
  }
}
