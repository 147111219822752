import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class FornecedoresService extends BaseService {

  constructor(
    private http: HttpClient
  ) { super(); }


  getFornecedores() {
    return this.http.get(
      this.UrlService + 'fornecedores'
    )
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  getFornecedoresPaginado(pagina: number, nome: string, nomeFantasia = false) {
    let filtro = "nome";
    if (nomeFantasia) filtro = "nomeFantasia";

    return this.http.get(this.UrlService + `fornecedores/paginado/${filtro}?pageIndex=` + pagina + "&search=" + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getFornecedoresPaginadoCombobox(pagina: number, search = '', filters: [], pessoaFisicaJuridica: 'T' | 'F' | 'J' = 'T') {
    const dict = { 'Razão Social': 'razaoSocial', 'Nome Fantasia': 'nomeFantasia', 'CNPJ': 'cnpj' };
    const filter = filters
      .map((option: any) => search.length ? dict[option.value] + '=' + encodeURIComponent(search) : '')
      .filter(f => f)
      .join('&');
    let query = filter.length ? '&' + filter : '';
    query += `&fisicaJuridica=${pessoaFisicaJuridica}`;

    return this.http.get(this.UrlService + 'fornecedores/paginado?pageIndex=' + pagina + query)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getTransportadoresPaginado(pagina: number, nome: string, nomeFantasia = false) {
    return this.http.get(this.UrlService + `fornecedores/paginado/transportadores/?nome=${nomeFantasia}?pageIndex=${pagina}&search=${encodeURIComponent(nome)}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getVerificaCpfCnpjFornecedor(cpfCpnj: string) {
    return this.http
      .get(this.UrlService + 'fornecedores/verificaCpfCnpjFornecedor/' + cpfCpnj)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  post(form) {
    return this.http.post(this.UrlService + 'fornecedores', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  salvarResumido(form) {
    return this.http.post(this.UrlService + 'fornecedores/salvarResumido', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  getFornecedorById(id: number) {
    return this.http.get(
      `${this.UrlService}fornecedores\/${id}`
    )
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  delete(id: number) {
    return this.http.delete(this.UrlService + `fornecedores/${id}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getWithFilters(consulta: any) {
    return this.http
      .post(this.UrlService + 'fornecedores/buscar', consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  getByCNPJ(cnpj: string) {
    return this.http
      .get(this.UrlService + 'fornecedores/obterPorCNPJ/' + encodeURIComponent(cnpj))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
