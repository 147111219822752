export interface SituacaoTributariaICMS {
  tipo: string;
  descricao: string;
  regime: string;
}

export const ListaTipoSituacaoTributariaICMS: SituacaoTributariaICMS[] = [
  { tipo: '101', descricao: 'ICMS 101 - Tributada com Permissão de Credito', regime: 'simples' },
  { tipo: '102', descricao: 'ICMS 102 - Tributada sem Permissão de Credito', regime: 'simples' },
  { tipo: '103', descricao: 'ICMS 103 - Isenção do ICMS para faixa de receita Bruta', regime: 'simples' },
  { tipo: '201', descricao: 'ICMS 201 - Tributada com Permissão de Credito e com cobrança de Substituição Tributária', regime: 'simples' },
  { tipo: '202', descricao: 'ICMS 202 - Tributada sem Permissão de Credito e com cobrança de Substituição Tributária', regime: 'simples' },
  { tipo: '203', descricao: 'ICMS 203 - Isenção do ICMS para faixa de receita Bruta e com cobrança de Substituição Tributária', regime: 'simples' },
  { tipo: '300', descricao: 'ICMS 300 - Imune', regime: 'simples' },
  { tipo: '400', descricao: 'ICMS 400 - Não tributada', regime: 'simples' },
  { tipo: '500', descricao: 'ICMS 500 - ICMS Cobrado anteriormente por ST ou Antecipação', regime: 'simples' },
  { tipo: '900', descricao: 'ICMS 900 - Outros', regime: 'simples' },
  { tipo: '00', descricao: 'ICMS 00 - Tributada Integralmente', regime: 'normal' },
  { tipo: '02', descricao: 'ICMS 02 – Tributação monofásica própria sobre combustíveis', regime: 'normal' },
  { tipo: '10', descricao: 'ICMS 10 - Tributada e com Cobrança do ICMS por Substituição Tributária', regime: 'normal' },
  { tipo: '15', descricao: 'ICMS 15 – Tributação monofásica própria e com responsabilidade pela retenção sobre combustíveis', regime: 'normal' },
  { tipo: '20', descricao: 'ICMS 20 - Com redução da Base de Cálculo', regime: 'normal' },
  { tipo: '30', descricao: 'ICMS 30 - Isenta ou não Tributada e com Cobrança do ICMS por Substituição Tributária', regime: 'normal' },
  { tipo: '40', descricao: 'ICMS 40 - Isenta', regime: 'normal' },
  { tipo: '41', descricao: 'ICMS 41 - Não Tributada', regime: 'normal' },
  { tipo: '50', descricao: 'ICMS 50 - Suspensão', regime: 'normal' },
  { tipo: '51', descricao: 'ICMS 51 - Diferimento', regime: 'normal' },
  { tipo: '53', descricao: 'ICMS 53 – Tributação monofásica sobre combustíveis com recolhimento diferido', regime: 'normal' },
  { tipo: '60', descricao: 'ICMS 60 - Cobrado Anteriormente por Substituição Tributária', regime: 'normal' },
  { tipo: '61', descricao: 'ICMS 61 – Tributação monofásica sobre combustíveis cobrada anteriormente', regime: 'normal' },
  { tipo: '70', descricao: 'ICMS 70 - Com redução da Base de Cálculo e Cobrança do ICMS por Substituição Tributária', regime: 'normal' },
  { tipo: '90', descricao: 'ICMS 90 - Outras', regime: 'normal' },
];

export const ListaTipoSituacaoTributariaICMSSimples = ListaTipoSituacaoTributariaICMS.filter(cst => cst.regime === 'simples');
export const ListaTipoSituacaoTributariaICMSRegimeNormal = ListaTipoSituacaoTributariaICMS.filter(cst => cst.regime === 'normal');
