import { Component, OnInit, ViewChild } from '@angular/core';
import { ParamConsulta } from '../../../general/models/paramConsulta';
import { MessageService } from '../../../services/message.service';
import { NotasFiscaisService } from '../../../services/notas-fiscais.service';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-busca-itens-remessa',
  templateUrl: './busca-itens-remessa.component.html',
  styleUrls: ['./busca-itens-remessa.component.css'],
})
export class BuscaItensRemessaComponent extends ModalCadastroComponent implements OnInit {
  constructor(
    private notasFiscaisService: NotasFiscaisService,
    private messageService: MessageService
  ) {
    super();
  }

  @ViewChild('modalConsulta', { static: true }) modalConsulta: ModalBasicComponent;
  notas;
  selecionados = [];
  paramConsulta = new ParamConsulta();
  exibirTodosRemetentes = false;
  clienteCNPJ = null;

  colunas = [
    { label: 'Série', col: 'serie', hide: true },
    { label: 'Número', col: 'numeroNotaFiscal', hide: true },
    { label: 'Remetente', col: 'nome', hide: false, limit: 35 },
    { label: 'Valor', col: 'valorTotal', hide: false, currency: true },
    { label: 'Saldo', col: 'saldo', hide: false, format: this.formatNumber },
  ];

  formatNumber(val) {
    return (val || 0).toString().replace('.', ',');
  }

  ngOnInit() {
    if (!this.prop) {
      return;
    }

    this.notas = this.prop;
    this.clienteCNPJ = this.prop.clienteCNPJ;
  }

  show() {
    this.modalConsulta.show();
  }

  onSort(paramConsulta) {
    this.loading['table'] = true;
    this.paramConsulta = paramConsulta;
    this.carregarNotas();
  }

  exibirTodosRemetentesChanged() {
    this.loading['table'] = true;
    this.carregarNotas();
  }

  async carregarNotas() {
    const filtroCliente = this.exibirTodosRemetentes ? null : this.clienteCNPJ;

    try {
      this.notas = await this.notasFiscaisService
        .buscarNotasRemessaPaginado(filtroCliente, this.paramConsulta)
        .toPromise();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading['table'] = false;
    }
  }

  async carregarItens() {
    this.loading['itens'] = true;

    try {
      let res = await this.notasFiscaisService
        .montarItensNFeRemessa(this.selecionados)
        .toPromise();

      if(res && res.itens.length > 0){
        res.itens = res.itens.map(item => {
          return {
            produto: item.item ? item.item.id : 0,
            descricaoProduto: item.item ? item.item.nome : item.descricao,
            unidadeMedida: item.unidade,
            quantidade: item.quantidade - (item.quantidadeCobrada || 0),
            preco: item.preco,
            valorDesconto: item.percentualDesconto
              ? item.percentualDesconto
              : item.valorDesconto,
            tipoDesconto: item.percentualDesconto ? 1 : 0,
            valorTotalItem: (item.quantidade - (item.quantidadeCobrada || 0)) * item.preco,
            itensFornecedores: item.item ? item.item.itensFornecedores : [],
            notaFiscalEntradaItemId: item.id,
            referenciaItem: item.item.referenciaItem,
          };
        });
      }
      this.closed.emit(res);
    } catch (error) {
      console.error(error);
      this.messageService.error(
        'Ocorreu um erro ao carregar os itens das notas',
        5000,
        'Atenção!',
        true
      );
    } finally {
      this.loading['itens'] = false;
    }
  }
}
