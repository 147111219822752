import { Cidade } from './cidade';

export interface UnidadeEmpresa {
    id: number,
    nome: string,
    nomeFantasia: string,
    inscricaoMunicipal: string,
    cnpj: string,
    inscricaoEstadual: string,
    localidade: string,
    complemento: string,
    bairro: string,
    numero: number,
    cep: string,
    cnaeFiscal: string,
    responsavel: string,
    foneResponsavel: string,
    foneEmpresa: string,
    email: string,
    emailMarketing: string,
    crt: number,
    contabilidadeId: number,
    tabelaPrecoId: number,
    unidadeEmpresaPagadorId: number,
    dataInclusao: Date,
    observacoes: string,
    cidadeId: number,
    cidade: Cidade,    
    usuarios: any,
    parametros: any,
    dataProrrogacaoBloqueio: any,
    diasAtraso?: any,
    motivoProrrogacaoBloqueio: string,
    clienteFaturamentoId: number
}