import { FormControl } from "@angular/forms";

export interface ValorConsulta {
  minimo: number;
  maximo: number;
}

export type ValorConsultaForm = {
  minimo: FormControl<number>;
  maximo: FormControl<number>;
}
