import { FormControl } from "@angular/forms";

export interface PeriodoConsulta {
  inicio: Date;
  fim: Date;
}

export type PeriodoConsultaForm = {
  inicio: FormControl<Date>;
  fim: FormControl<Date>;
}
