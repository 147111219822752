<label *ngIf="label" for="">{{ label }}</label>
<div class="form-radio form-control" style="border: unset;">
  <div class="row">
    <div *ngFor="let option of options" class="radio radio-inline">
      <label style="margin-bottom: 0px; cursor: pointer;" [ngStyle]="option.style">
        <input [id]="id" [name]="id" type="radio" [formControl]="selected" [value]="option.value" (change)="handleOnChange()">
        <i class="helper" [ngClass]="option.icon"></i><span>{{ option.label }}</span>
      </label>
    </div>
  </div>
</div>
