import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { catchError, finalize, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ParamConsulta } from '../general/models/paramConsulta';
import { Consulta } from '../model/consulta';
import { BaseService } from './base.service';
import { MessageService } from './message.service';
import { BuscarNfeRetornoAtivoImobilizadoViewModel } from '../model/BuscarNfeRetornoAtivoImobilizadoViewModel';
import { NotasFiscaisODataViewModel } from 'models/notas-fiscais/NotasFiscaisODataViewModel';
import { ConsultaPaginadaODataViewModel } from '../model/genericas/ConsultaPaginadaODataViewModel';

@Injectable()
export class NotasFiscaisService extends BaseService {

  constructor(private http: HttpClient, private message: MessageService) { super(); }

  imprimirRastreabilidadeLotesMedicamento(numeroNotaEntrada: number) {
    const idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Rastreabilidade',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    });

    return this.http
      .post(this.UrlService + 'notasFiscais/imprimirRastreabilidadeLotesMedicamento', numeroNotaEntrada,
        { responseType: 'blob' })
      .pipe(finalize(() => this.message.clear(idToast)))
      .pipe(catchError(super.parseErrorBlob));
  }

  imprimirRelatorioResumoDeVendasPorNCM(form) {
    const idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Resumo de Vendas por NCM',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    });

    return this.http
      .post(this.UrlService + 'notasFiscais/imprimirRelatorioResumoDeVendasPorNCM', form,
        { responseType: 'blob' })
      .pipe(finalize(() => this.message.clear(idToast)))
      .pipe(catchError(super.parseErrorBlob));
  }

  imprimirRetencaoDeImpostos(consulta: any) {
    const idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Notas Fiscais com Retenções de Impostos',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    });

    return this.http
      .post(this.UrlService + 'notasFiscais/imprimirRetencaoDeImpostos', consulta,
        { responseType: 'blob' })
      .pipe(finalize(() => this.message.clear(idToast)))
      .pipe(catchError(super.parseErrorBlob));
  }

  imprimirNfe(nfeId: number, formato: string) {
    const params = {
      nfeId: nfeId,
      formato: formato
    }

    return this.http
      .post(this.UrlService + 'notasFiscais/imprimirNfe', params, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  imprimirCCe(nfeId: number, formato: string) {
    const params = {
      nfeId: nfeId,
      formato: formato
    }
    return this.http
      .post(this.UrlService + 'notasFiscais/imprimirCce', params)
      .pipe(map((res: any) => res.data));
  }

  getNotasDimensaoPorEmpresa(consulta: any) {
    return this.http
      .post(this.UrlService + 'notasFiscais/notasPorEmpresa', consulta).pipe(
        map((res: any) => <Consulta[]>res.data)
        , catchError(super.serviceError));
  }

  getNotasPaginadoOData(form): Observable<ConsultaPaginadaODataViewModel<NotasFiscaisODataViewModel>> {
    const odataQuery = `?$select=Id,numeroNotaFiscal, Status, ValorTotal, CodigoResposta,
      DescricaoResposta, Emissao, TipoNFe, TipoNfeID, CodigoFiscalID, NotaFiscalEstornoID,
      Finalidade, PossuiSaldoParaNotasFilhas&$top=10${this.getOrderAndIndexOData(form)}$count=true
      &$expand=Cliente($select=RazaoSocial, Email, Cnpj), CartaCorrecao($select=Id, Status, Descricao),
      Itens($select=Id; $expand=CodigoFiscal($select=Codigo), Declaracoes($select=NDI))`;
    return this.http.post(this.UrlService + `odata/notasFiscais${odataQuery}`, form)
      .pipe(map(super.extractODataResult), catchError(super.serviceError));
  }

  enviarNfe(notaFiscalId) {
    return this.http.post(this.UrlService + 'notasfiscais/enviar', notaFiscalId)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getDetalhesNfe(notaFiscalID) {
    return this.http.get(this.UrlService + `notasFiscais/getDetalhesNFe/${notaFiscalID}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getEmailsNFe(nfeId) {
    return this.http.get(this.UrlService + `notasFiscais/getEmailsNFe/${nfeId}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getNfeOperacao(notaFiscalId) {
    return this.http.get(this.UrlService + `notasfiscais/getNfeOperacao/${notaFiscalId}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getNotaMae(notaFiscalId) {
    return this.http.get(this.UrlService + `notasfiscais/getNotaMae/${notaFiscalId}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  gerarNotaSaidaImportacao(notaFiscalId) {
    return this.http.get(this.UrlService + `notasfiscais/gerarNotaSaidaImportacao/${notaFiscalId}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  gerarNotaFiscalOperacao(form, id: number) {
    if (id > 0) {
      return this.http.put(this.UrlService + 'notasfiscais/AtualizarNotaFiscalOperacao', form)
        .pipe(map(super.extractData)
          , catchError(super.serviceError));
    } else {
      return this.http.post(this.UrlService + 'notasfiscais/GerarNotaFiscalOperacao', form)
        .pipe(map(super.extractData)
          , catchError(super.serviceError));
    }
  }

  gerarNotaFiscalDevolucao(form) {
    return this.http.post(this.UrlService + 'notasfiscais/GerarNotaFiscalRetDev', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  preVisualizarNotaFiscalDevolucao(form) {
    return this.http.post(this.UrlService + 'notasfiscais/PreVisualizarRetornoDevolucao', form, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  preVisualizarNfe(form) {
    return this.http.post(this.UrlService + 'notasfiscais/PreVisualizar', form, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  enviarEmail(nfceId: number, email: string[], emitirBoleto = true) {
    const nfe: Object = {
      'nfe': nfceId,
      'email': email,
      'emitirBoleto': emitirBoleto
    };

    return this.http.post(this.UrlService + 'notasfiscais/email', nfe)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  cancelarNfe(nfeId: number, motivo: string) {
    const param: Object = {
      'notaFiscalID': nfeId,
      'motivo': motivo
    };

    return this.http.post(this.UrlService + 'notasfiscais/cancelar', param)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  consultarNfe(nfeId: number) {
    return this.http.post(this.UrlService + 'notasfiscais/consultar', nfeId)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  corrigirNfe(nfeId: number, texto: string) {
    const param: Object = {
      'id': nfeId,
      'texto': texto
    };

    return this.http.post(this.UrlService + 'notasfiscais/corrigir', param)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  inutilizarNfe(nfeId: number, motivo: string) {
    const param: Object = {
      'id': nfeId,
      'motivo': motivo
    };

    return this.http.post(this.UrlService + 'notasfiscais/inutilizar', param)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  estornarNfe(nfeId: number) {
    return this.http.post(this.UrlService + 'notasfiscais/estornar', nfeId)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  saidasPorEstado(consulta: any) {
    const idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Resumo de Saídas por Estado',
      showClose: false, timeout: 20000, theme: 'bootstrap', closeOther: true
    });

    return this.http
      .post(this.UrlService + 'notasfiscais/saidasPorEstado', consulta, { responseType: 'blob' })
      .pipe(finalize(() => this.message.clear(idToast)))
      .pipe(catchError(super.parseErrorBlob));
  }

  simulaPagamentosPrazo(condicaoPagamento, valorTotal, dataVencimento) {
    const form = { condicaoPagamento, valorTotal, dataVencimento };
    return this.http.post(`${this.UrlService}notasfiscais/SimulaPagamentosPrazo`, form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  importarXmlNfe(file: any) {
    return this.http
      .post(this.UrlService + 'notasfiscais/ImportaXmlNfe', file).pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  imprimirRelatorioGerencial(filtros) {
    return this.http
      .post(this.UrlService + 'notasFiscais/imprimirRelatorioGerencial', filtros,
        { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  private impressaoNfe = new BehaviorSubject<Object>(null);
  solicitacaoImpressao = this.impressaoNfe.asObservable();

  organizarImpressao(id?, param?) {
    if (id != null && param != null) {
      this.impressaoNfe.next({ id: id, param: param });
    } else {
      this.impressaoNfe.next(null);
    }
  }

  imprimirEtiqueta(notaFiscalID) {
    return this.http
      .get(this.UrlService + 'notasFiscais/ImprimirEtiquetaNFe/' + notaFiscalID, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  montarItensNFeRetorno(notas, buscaDadosAdicionais: boolean, retornaNumerosRemessa: boolean) {
    const form = { notas, buscaDadosAdicionais, retornaNumerosRemessa };
    return this.http
      .post(this.UrlService + 'notasfiscais/montarItensNFeRetorno', form).pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  buscarNotasRetornoPaginado(clienteID: number | null, consulta = new ParamConsulta()) {
    const queryCliente = clienteID ? `?clienteID=${clienteID}` : '';

    return this.http.post(this.UrlService + `notasFiscais/BuscarNotasRetornoPaginado${queryCliente}`, consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  buscarNotasRomaneioPaginado(transportadorId: number | null, consulta = new ParamConsulta()) {
    return this.http
      .post(this.UrlService + `notasFiscais/BuscarNotasRomaneioPaginado?transportadorId=${transportadorId}`, consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  montarItensNFeRomaneio(notas) {
    return this.http
      .post(this.UrlService + 'notasfiscais/MontarItensNFeRomaneio', notas)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  montarItensNFeRemessa(notas) {
    return this.http
      .post(this.UrlService + 'notasfiscais/montarItensNFeRemessa', notas)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError)
      );
  }

  montarItensRomaneio(notas) {
    return this.http
      .post(this.UrlService + 'notasfiscais/MontarItensRomaneio', notas)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  buscarNotasRemessaPaginado(clienteCNPJ: string | null, consulta = new ParamConsulta()) {
    const queryCliente = clienteCNPJ ? `?clienteCNPJ=${clienteCNPJ}` : '';

    return this.http
      .post(this.UrlService + `notasFiscais/BuscarNotasRemessaPaginado${queryCliente}`, consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  listarNotasFiscais(consulta) {
    return this.http.post(this.UrlService + 'notasFiscais/ListarNotasFiscais', consulta)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  listarNotasFiscaisItens(selecionadas: number[]) {
    return this.http.post(this.UrlService + 'notasFiscais/ListarNotasFiscaisItens', selecionadas)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  listarNotasFiscaisPorContaOrdem(consulta, clienteId, industrializadorDest) {
    return this.http.post(this.UrlService + `notasFiscais/ListarNFContaOrdem?clienteId=${clienteId}&destinatario=${industrializadorDest}`, consulta)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  montarItensNFePorContaOrdem(notas) {
    return this.http
      .post(this.UrlService + 'notasfiscais/montarItensContaOrdem', notas)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  buscarUltimaEmissaoWEB(empresa: number) {
    return this.http.get(this.UrlService + 'notasFiscais/GetUltimaEmissaoWEB?unidadeEmpresaID=' + empresa)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  imprimirEmissoesPorCfop(consulta) {
    return this.http
      .post(this.UrlService + 'notasFiscais/ImprimirEmissoesPorCfop/',
        consulta,
        { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  imprimirMapaRemessasRetornos(consulta) {
    return this.http
      .post(this.UrlService + 'notasFiscais/ImprimirMapaRemessasRetornos/',
        consulta,
        { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  xmlNfeCancelada(notaFiscalID) {
    return this.http
      .post(this.UrlService + 'notasFiscais/XmlNfeCancelada/', notaFiscalID,
        { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  baixarBoleto(notaFiscalID) {
    return this.http
      .post(this.UrlService + 'notasFiscais/gerarBoleto/', notaFiscalID)
      .pipe(map((res: any) => res.data));
  }

  buscarNotasPorClientePaginado(parametros: any) {
    return this.http.post(this.UrlService + 'notasFiscais/GetNotasFaturadas/', parametros)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getItensNFeFaturada(nfeID: any) {
    return this.http.post(this.UrlService + 'notasfiscais/GetItensNfeFaturada', nfeID)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getNotasReferenciadas(consulta: any) {
    return this.http.post(this.UrlService + 'notasfiscais/GetNotasReferenciadas', consulta)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  imprimirNFesVendaPCliente(datas: any) {
    return this.http
      .post(this.UrlService + 'notasFiscais/RelacaoNFesVendaPorCliente/', datas,
        { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  imprimirVendasPorProduto(consulta: any) {
    return this.http
      .post(this.UrlService + 'notasFiscais/RelatorioVendasPorProduto/', consulta,
        { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  obterDadosNFe(nfId: number) {
    return this.http.get(this.UrlService + `notasfiscais/getDadosNFe/${nfId}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  recuperarItensRomaneio(fornecedorId) {
    return this.http.get(this.UrlService + `notasfiscais/recuperarItensRomaneio/${fornecedorId}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  buscarNfeRetornoAtivoImobilizado(consulta: BuscarNfeRetornoAtivoImobilizadoViewModel) {
    return this.http.post(this.UrlService + `notasFiscais/buscarNfeRetornoAtivoImobilizado`, consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  montarItensNFeRetornoRemessaAtivoImobilizado(nfeId) {
    return this.http
      .post(this.UrlService + 'notasFiscais/montarItensNFeRetornoRemessaAtivoImobilizado', nfeId).pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  validaCSTCFOPRemessaAtivoImobilizado(cfopCodigo: String) {
    return this.http
      .post(this.UrlService + 'notasFiscais/validaCSTCFOPRemessaAtivoImobilizado', cfopCodigo).pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  baixarXmlDI(nfeId: any) {
    return this.http
      .get(this.UrlService + `notasFiscais/baixarXmlDI/${nfeId}`, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob))
  }

  importarTxtSefaz(base64: string) {
    return this.http
      .post(this.UrlService + 'notasFiscais/ImportarTxtSefaz', { base64 })
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  getDadosEdicaoCfopNota(notaFiscalID: number) {
    return this.http.get(this.UrlService + `notasFiscais/DadosEdicaoCfopNota/${notaFiscalID}`)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  editarCfopNotaSaida(notaFiscalID, dados) {
    return this.http.post(this.UrlService + `notasFiscais/EditarCfopNotaSaida/${notaFiscalID}`, dados)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }
}
