import { MessageService } from '../../../services/message.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { TipoCampoCartaCorrecao } from '../../../model/TipoCampoCartaCorrecao';

@Component({
  selector: 'app-carta-correcao-cte',
  templateUrl: './carta-correcao-cte.component.html',
  styleUrls: ['./carta-correcao-cte.component.css']
})
export class CartaCorrecaoCteComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalCarta', { static: true }) modalCarta: ModalBasicComponent;
  @Input() cte: any;
  cartaCorrecao: any;
  campoAlterado = '';

  constructor(
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit() {
    this.cte = this.prop.cte;
    this.visualizarCartaCorrecao(this.cte);
  }

  show() {
    this.modalCarta.show();
  }

  close() {
    this.modalCarta.hide();
  }
  onClose() {
    this.closed.emit(this.id);
  }

  visualizarCartaCorrecao(cte: any) {
    const cartasCorrecao = cte?.eventos?.slice()
        .filter(cc => cc.status === 'A' && cc.codigoEvento === '110110')
        .sort((a, b) => b.id - a.id);
    
    if (cartasCorrecao.length === 0) {
      this.messageService.error('Não foram encontradas cartas de correção aprovadas para esse CT-e.', 5000);
      return;
    }

    this.cartaCorrecao = cartasCorrecao && cartasCorrecao.length > 0 ? cartasCorrecao[0] : '';

    const listaCampos = TipoCampoCartaCorrecao.listar(true);
    const campo = listaCampos.find(x => x.grupo === this.cartaCorrecao.grupoAlterado && x.campo === this.cartaCorrecao.campoAlterado);
    if (campo) {
      this.campoAlterado = campo.descricao;
    } else {
      this.campoAlterado = this.cartaCorrecao.grupoAlterado + ' - ' + this.cartaCorrecao.campoAlterado;
    }
  }
}
