/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormGroup } from '@angular/forms';
import { StorageFunctions, IParamConsulta } from '@generals';
import { ConsultaPaginadaViewModel, DimDatatableColumn } from '@models';
import { IConsultaPadrao } from './consulta-padrao.interface';
import { MessageService } from '../../services/message.service';

/**
 * @class ConsultaPadrao<T>
 * @description Classe base para componentes de consulta.
 */
export class ConsultaPadrao<T> implements IConsultaPadrao {

  /**
   * Construtor da classe.
   *
   * @param {MessageService} message - Service para emitir mensagens de feedback na tela, como sucessos, erros, etc.
   * @param {StorageFunctions} storage - Service para interagir com o armazenamento.
   */
  constructor(
    componentName: string,
    public message: MessageService,
    public storage: StorageFunctions,
  ) {
    this.componentName = componentName;

    this.setForm(this.getFormDefault());
  }

  /**
   * Armazena o nome da subclasse como chave para acesso ao storage.
   *
   * @type {string}
   * @public
   */
  public componentName: string = undefined;

  /**
   * Armazena os resultados e informações de paginação de uma consulta.
   *
   * @type {ConsultaPaginadaViewModel<T>}
   * @public
   *
   * @description
   * Esta propriedade representa os dados retornados por uma consulta paginada, incluindo:
   *   - `items`: Os itens da página atual da consulta (um array do tipo genérico `T`).
   *   - `pageIndex`: O índice da página atual (baseado em 1).
   *   - `pageSize`: A quantidade de itens por página.
   *   - `totalItems`: O número total de itens na consulta.
   *   - `totalPages`: O número total de páginas na consulta.
   *
   * @remarks
   * - O tipo genérico `T` permite que esta propriedade seja usada com diferentes tipos de dados em diferentes consultas.
   * - Os valores iniciais são definidos para representar uma consulta vazia na primeira página.
   */
  public searchData: ConsultaPaginadaViewModel<T> = {
    items: [] as T[],
    pageIndex: 1 as number,
    pageSize: 10 as number,
    totalItems: 0 as number,
    totalPages: 1 as number,
  };

  /**
   * Colunas da tabela de dados.
   *
   * Define a estrutura e configuração das colunas exibidas no componente `dim-datatable`.
   * Cada elemento do array representa uma colunac.
   * @type {DimDatatableColumn[]}
   */
  public columns: DimDatatableColumn[] = [];

  /**
   * Controla a exibição da coluna de seleção (checkbox) no componente `dim-datatable`.
   *
   * @type {boolean}
   * @default false
   *
   * @remarks
   * Quando ativada (`true`), é crucial fornecer um array para o `@Input selecionados` do `dim-datatable`.
   * Este array é modificado diretamente em memória pelo `dim-datatable` e não há como saber quando foi modificado.
   */
  public selectable = false;

  /**
   * Armazena um snapshot do último formulário consultado.
   *
   * @type {any | undefined} - O snapshot do formulário, ou null se nenhum snapshot foi feito ainda.
   * @public
   *
   * @description
   * Este snapshot permite o acesso aos valores do formulário no momento da consulta,
   * mesmo que o formulário seja modificado posteriormente. É especialmente útil para
   * garantir que as exportações de dados utilizem os mesmos filtros que geraram a consulta original.
   */
  public formSnapshot: any = undefined;

  /**
   * Formulário padrão utilizado para resetar o formulário reativo.
   *
   * @type {FormGroup}
   * @public
   *
   * @description
   * Este formulário armazena o estado inicial do formulário reativo.
   * Ele é utilizado para resetar o formulário aos seus valores originais quando necessário.
   *
   * @remarks
   * Esta propriedade é inicializada como `undefined` e recebe o valor do formulário reativo no momento da criação do componente.
   * O valor deste formulário é mantido durante todo o ciclo de vida do componente.
   */
  public formDefault: FormGroup = undefined;

  /**
   * Formulário reativo compartilhado com o componente.
   *
   * @type {FormGroup}
   * @public
   *
   * @description
   * Este formulário é utilizado para gerenciar os dados do formulário reativo no componente.
   * Ele é compartilhado com o template do componente para permitir a vinculação de dados bidirecional.
   *
   * @remarks
   * Esta propriedade é inicializada como `undefined` e recebe o valor do formulário reativo no momento da criação do componente.
   * O valor deste formulário é atualizado automaticamente à medida que o usuário interage com o formulário no template.
   */
  public form: FormGroup = undefined;

  /**
   * Indica se existe uma consulta armazenada no storage.
   *
   * @type {boolean}
   * @public
   *
   * @description
   * Esta propriedade é utilizada para controlar a exibição do dropdown do botão de consulta.
   * Se houver uma consulta armazenada no storage, o valor desta propriedade será `true`, habilitando o dropdown.
   * Caso contrário, o valor será `false`, desabilitando o dropdown.
   */
  public hasLastSearch = false;

  /**
   * Armazena os itens selecionados pelo usuário quando a seleção está habilitada.
   *
   * @type {T[]}
   * @public
   *
   * @description
   * Este array armazena os itens selecionados pelo usuário quando a propriedade `selectable` do componente é definida como `true`.
   * A seleção é persistente, ou seja, os itens permanecem selecionados mesmo após a troca de página ou a ordenação dos dados.
   *
   * Este array é diretamente modificado em memória e não há como saber quando o componente `dim-datatable` adiciona ou remove valores deste array.
   *
   * @example
   * No componente ConsultaCreditoComissoesComponent eu fiz da seguinte forma:
   *
   * No .ts:
   * ```typescript
   * new FormGroup({
   *  representantes: new FormControl([]),
   *  lancarContasAPagar: new FormControl(false),
   *  vencimento: new FormControl(null),
   * });
   *
   * get representantesSelecionados() {
   *  return this.formCreditar.get('representantes').value;
   * }
   * ```
   *
   * No template:
   * ```html
   * <app-dim-datatable ... [selecionados]="representantesSelecionados" ...
   * ```
   */
  public selected: T[] = [];

  // Utilizado para configurar os states de carregamento do componente.
  public loading: AcoesConsultaPadrao = {
    request: false, // Para todas as requisições. Desejado que todos os campos e botões utilizem essa propriedade para desabilitarem.
    search: false, // Para a requisição de busca da consulta. Vide método `search`
    salvando: false, // Para utilizar em um botão de salvamento. Desejado que só o botão de salvar utilize essa propriedade pro efeito do <app-loading></app-loading>
  };

  public exportEnabled = false;
  public exportForm: FormGroup<any> = undefined;

  get items(): T[] {
    return [...this.searchData.items];
  }

  get paramConsulta() {
    return this.form.get('paramConsulta');
  }

  /**
   * Define o formulário reativo principal e, se ainda não existir, o formulário padrão.
   *
   * @param {FormGroup} form - O formulário reativo a ser definido.
   *
   * @description
   * Esta função atribui o formulário reativo fornecido à propriedade `form`.
   * Se a propriedade `formDefault` ainda não tiver sido definida, ela também será atribuída ao formulário fornecido.
   */
  public setForm(form: FormGroup): void {
    this.form = form;
    if (!this.formDefault) {
      this.formDefault = form;
    }
  }

  /**
   * Reseta o formulário reativo para o seu estado inicial.
   *
   * @description
   * Esta função redefine o formulário reativo para o seu estado original, utilizando o valor armazenado na propriedade `formDefault`.
   * Isso é útil para limpar o formulário após um envio ou para restaurar os valores padrão.
   */
  public resetForm(): void {
    this.form.reset();
    if (this.formDefault) {
      this.form = this.getFormDefault();
    }
  }

  /**
   * Configura a última consulta a partir do armazenamento e executa a busca, se `consultarSozinho` ou `bypass` forem true.
   *
   * @param {Object} [options] Opções para controlar o comportamento do método.
   * @param {boolean} [options.bypass=false] Se definido como `true`, será executado o método `search`.
   */
  public useLastSearchFromStorage(options: { bypass?: boolean } = {}): void {
    const { bypass = false } = options;
    if (this.setLastSearchFromStorage({ bypass })) {
      this.search();
    }
  }

  /**
   * Define o último filtro de pesquisa a partir do armazenamento local, opcionalmente aplicando-o ao formulário atual.
   *
   * @param {Object} [options] - Opções adicionais para controlar o comportamento da função.
   * @param {boolean} [options.bypass=false] - Se `true`, o filtro será aplicado ao formulário atual,
   *  mesmo que a opção `consultarSozinho` esteja desativada.
   *
   * @returns {boolean} - `true` se o filtro foi aplicado com sucesso ao formulário, `false` caso contrário.
   *
   * @description
   * Esta função recupera o último filtro de pesquisa armazenado no `localStorage`.
   * Se a opção `bypass` for `true` ou se a opção `consultarSozinho` do filtro estiver habilitada,o filtro será aplicado
   * ao formulário atual. Caso contrário, apenas a propriedade `hasLastSearch` será definida como `true` para indicar
   * que existe um filtro salvo.
   *
   * @remarks
   * Utiliza o nome do componente como chave de acesso ao storage.
   */
  public setLastSearchFromStorage(options: { bypass?: boolean } = {}): boolean {
    const { bypass = false } = options;
    const { form, consultarSozinho } = this.storage.getStorageFiltersObject(this.componentName);
    if (form) {
      this.hasLastSearch = true;
      if (consultarSozinho || bypass) {
        this.form.patchValue(form);
        return true;
      }
    }
    return false;
  }

  /**
   * Salva os valores atuais do formulário no armazenamento local.
   *
   * @description
   * Este método obtém os valores brutos do formulário reativo usando `this.form.getRawValue()` e os armazena no `localStorage`
   * utilizando o serviço `this.storage` e a chave `this.componentName`.
   *
   * @remarks
   * Este método idealmente deve ser chamado após a consulta ter retornado.
   *
   * @example
   * ```typescript
   * this.searchData = await // Sua consulta aqui
   * this.setCurrentSearchToStorage();
   * ```
   */
  public setCurrentSearchToStorage(): void {
    this.storage.setStorageFilters(this.componentName, {...this.form.getRawValue()});
    this.hasLastSearch = true;
    this.setFormSnapshot();
  }

  /**
   * Valida se os dados da consulta paginada possuem a quantidade necessária de páginas para o index da página selecionada.
   *
   * @param {ConsultaPaginadaViewModel<T>} searchData - Os dados da consulta paginada a serem validados.
   * @returns {boolean} - Retorna `true` se `pageIndex` for menor ou igual ao total de páginas ou se `totalPages` for 0.
   *                      Retorna `false` se `pageIndex` for maior que o total de páginas.
   */
  public searchDataHasValidPageIndex(searchData: ConsultaPaginadaViewModel<T>): boolean {
    return searchData.totalPages === 0 || searchData.pageIndex <= searchData.totalPages;
  }

  /**
   * Configura o index da página para consulta como o total de páginas.
   * Também salva o filtro com o index atualizado.
   *
   * @param {ConsultaPaginadaViewModel<T>} searchData - Os dados da consulta paginada a serem configurados.
   */
  public setSearchDataValidPageIndex(searchData: ConsultaPaginadaViewModel<T>) {
    this.setPageIndex(searchData.totalPages);
    this.setCurrentSearchToStorage();
  }

  public setPageIndex(pageIndex: number) {
    this.paramConsulta.get('index').setValue(pageIndex);
  }

  /**
   * Setter para `searchData`.
   *
   * Valida a quantidade total de páginas com o índice da página informado. Se a validação retornar `true`,
   * configura o total de páginas, que equivale à última página no índice da página, e executa uma nova bosca com `await`.
   *
   * @param {ConsultaPaginadaViewModel<T>} searchData - Os dados da consulta paginada a serem definidos.
   * @returns {Promise<void>} - Retorna uma `Promise` que resolve quando a operação de busca é concluída.
   */
  public async setSearchData(searchData: ConsultaPaginadaViewModel<T>) {
    if (this.searchDataHasValidPageIndex(searchData)) {
      this.searchData = searchData;
    } else {
      this.setSearchDataValidPageIndex(searchData);
      await this.search();
    }
  }

  /**
   * Configura a última consulta realizada.
   *
   * Armazena o estado atual do formulário, obtido através de `this.form.getRawValue()`,
   * na propriedade `formSnapshot`.
   */
  public setFormSnapshot(): void {
    this.formSnapshot = {...this.form.getRawValue()};
  }

  public clearFormSnapshot(): void {
    this.formSnapshot = undefined;
  }

  public clearSearchFromStorage() : void {
    this.storage.removeKeyFromFiltroDeConsultas(this.componentName);
    this.clearFormSnapshot();
    this.hasLastSearch = false;
  }

  /**
   * Define os novos parâmetros de consulta e realiza uma nova busca.
   *
   * @param {IParamConsulta} pc - Os parâmetros da consulta.
   *
   * @description
   * Este método é usado para definir a ordenação dos dados no componente de consulta.
   * Ele recebe um objeto `IParamConsulta` que contém os parâmetros da consulta.
   * Em seguida, o método chama a implementação da busca (`this.search()`) para atualizar os resultados.
   *
   * @remarks
   * - Se o componente de consulta não implementar o método `search()`, uma implementação local será utilizada e uma mensagem de erro será exibida.
   */
  public setOrdenation(pc: IParamConsulta): void {
    this.paramConsulta.setValue({...pc});
    this.setCurrentSearchToStorage();
    this.search();
  }

  /**
   * Define os novos parâmetros de consulta e realiza uma nova busca.
   *
   * @param {IParamConsulta} pc - Os parâmetros da consulta.
   *
   * @description
   * Este método é usado para definir a ordenação dos dados no componente de consulta.
   * Ele recebe um objeto `IParamConsulta` que contém os parâmetros da consulta.
   * Em seguida, o método chama a implementação da busca (`this.search()`) para atualizar os resultados.
   *
   * @remarks
   * Se o componente de consulta não implementar o método `search()`, uma implementação local será utilizada e uma mensagem de erro será exibida.
   */
  public setPagination(pc: IParamConsulta): void {
    this.paramConsulta.setValue({...pc});
    this.setCurrentSearchToStorage();
    this.search();
  }

  /**
   * Implementação padrão da função de busca.
   *
   * @description
   * Este método é uma implementação padrão da função de busca que será utilizada caso o componente de consulta não forneça sua própria implementação.
   * Ele exibe uma mensagem de erro informando que o método `search` não foi implementado no componente.
   *
   * @remarks
   * Este método deve ser sobrescrito no componente de consulta para fornecer a lógica de busca específica.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async search() {
    this.missingMethodError('search');
    return;
  }

  public getFormDefault(): FormGroup<any> {
    this.missingMethodError('getFormDefault');
    return new FormGroup({});
  }

  private missingMethodError(methodName = ''): void {
    setTimeout(() => {
      this.message.error(
        'Método `' + methodName + '` não implementado. Consulte `consulta-padrao.ts` para mais informações.',
        0,
        'Erro de implementação'
      );
    }, 0);
  }
}

interface AcoesConsultaPadrao {
  request: boolean;
  search?: boolean;
  excluindo?: boolean;
  salvando?: boolean;
  exportando?: boolean;
}
