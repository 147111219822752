export class TitulosCobranca {
    id: number;
    clienteId: number;
    parcela: string;
    unidadeEmpresaId: number;
    emissao: Date;
    vencimento: Date;
    pagamento?: Date;
    valorPago: number;
    valorParcela: number;
    valorDescontoPontualidade: number;
    valorAbatimento: number;
    valorDespesasFinanceiras: number;
}

export interface TitulosCobrancaViewModel {
  id?: number;
  serie?: string;
  statusBanco?: string;
  numero?: number;
  parcela?: string;
  clienteId?: number;
  notaFiscalId?: number;
  notaFiscalServicoId?: number;
  cliente: { id: number };
  emissao?: Date;
  vencimento?: Date;
  valorParcela?: number;
  pagamento?: number;
  dataCredito?: number;
  valorPago?: number;
  imprimirBoleto?: boolean;
  observacoes?: string;
  valorDescontoPontualidade?: number;
  valorAbatimento?: number;
  valorDespesasFinanceiras?: number;
  valorTarifa?: number;
  possuiTentativaPagamento?: boolean;
  hasSafe2PayTransactionID?: boolean;
  tituloBaixado?: boolean;
  valorBoleto?: number;
  vencimentoMenorOuIgualAHoje?: boolean;
  intermediarioSafe2Pay?: number;
  possuiBoletoDim?: boolean;
}

export interface CadastroTitulosCobrancaViewModel {
  id?: number;
  cliente: number;
  portador?: number;
  serie?: string;
  numero?: number;
  parcela?: string;
  emissao?: Date;
  vencimento: Date;
  valorParcela: number;
  valorFatura: number;
  valorAbatimento?: number;
  valorDescontoPontualidade?: number;
  valorDespesasFinanceiras?: number;
  contaRecebedora?: number;
  contaRecebedoraDetalhes: CadastroContasCorrentesViewModel;
  numeroTituloBanco?: string;
  valorTarifa?: number;
  statusBanco?: string;
  descricaoStatusBanco?: string;
  observacoes?: string;
  vendedor: number;
  valorComissaoFaturamento?: number;
  valorComissaoCobranca?: number;
  juros?: number;
  desconto?: number;
  dataCredito?: Date;
  valorPago?: number;
  pagamento?: Date;
  pedidoId?: number;
  vendedorIdNf?: number;
  tipoComissaoId?: number;
  valorComPreposto?: number;
  valorComCobrancaPreposto?: number;
  dataPrevisaoCredito?: Date;
  possuiBoletoDim: boolean;
}

export interface ContasCorrentesViewModel {
  id: number;
  descricao?: string;
  inativo?: boolean;
  naoEntraFluxoCaixa?: boolean;
  intermediador?: string;
  intermediadorRecebimentoID?: number;
}

export interface CadastroContasCorrentesViewModel {
  id?: number;
  descricao?: string;
  banco?: number;
  agencia?: number;
  digitoAgencia?: string;
  cedente?: number;
  digitoCedente?: number;
  inativo?: boolean;
  naoEntraFluxoCaixa?: boolean;
  beneficiario?: ContaCorrenteDadosBeneficiarioViewModel;
  cobranca?: ContaCorrenteDadosCobrancaViewModel;
  conciliacao?: ContaCorrenteDadosConciliacaoViewModel;
  contabilizacao?: ContaCorrenteDadosContabilizacaoViewModel;
  intermediadorRecebimentoID?: number;
}

export interface ContaCorrenteDadosBeneficiarioViewModel {
  cnpjBeneficiario?: string;
  nomeBeneficiario?: string;
  cidadeBeneficiario?: number;
  cepBeneficiario?: string;
  bairroBeneficiario?: string;
  enderecoBeneficiario?: string;
  numeroBeneficiario?: string;
  estadoBeneficiario?: number;
}

export interface ContaCorrenteDadosCobrancaViewModel {
  carteira?: string;
  carteiraVariacao?: number;
  convenio?: number;
  jurosMora?: number;
  protestarAposDias?: number;
  baixaAposDias?: number;
  multa?: number;
  sequenciaRemessa?: number;
  bancoPadrao?: number;
  instrucaoBoleto?: string;
  emissorBoleto?: number;
  expedidorBoleto?: number;
  semStatus?: boolean;
  enviado?: boolean;
  registrado?: boolean;
  baixado?: boolean;
  intermediadorRecebimentoID?: number;
}

export interface ContaCorrenteDadosConciliacaoViewModel {
  dataFechamento?: Date;
}

export interface ContaCorrenteDadosContabilizacaoViewModel {
  contaContabilID?: number;
}
