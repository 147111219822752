<app-modal-basic #modal [hideHeader]='true' [hideFooter]='true' (closed)="onClose()" [dialogClass]="'modal-lg'"
  modalContentId="modal-enviar-carta-correcao">
  <div class="app-modal-body">
    <div class="row modalheader" *ngIf="!error">
      <img class="img-responsive img-center" src="../../../../assets/images/modais/corrigir.png" alt="">
    </div>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info">
            <span class="infocc">A <strong>Carta de Correção</strong> é disciplinada pelo Art. 58-B do CONVÊNIO/SINIEF
              06/89:<br />
              Fica permitida a utilização de carta de correção, para regularização de erro ocorrido na emissão de
              documentos fiscais relativos à Conhecimento de Transporte eletrônico prestação de serviço de transporte,
              desde que o erro não esteja relacionado com: <br />
              I - as variáveis que determinam o valor do imposto tais como: base de cálculo, alíquota, diferença de
              preço, quantidade, valor da prestação;<br />
              II - a correção de dados cadastrais que implique mudança do emitente, tomador, remetente ou do
              destinatário;<br />
              III - a data de emissão ou de saída.</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-12">
          <label>Campo Alterado</label>
          <ng-select [items]="listaCampos" formControlName="alteracao" placeholder="Selecione" bindLabel="descricao"
            [disableControl]='loading["enviando"]' appendTo="#modal-enviar-carta-correcao">
          </ng-select>
          <show-errors [control]="form.controls.alteracao"></show-errors>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Alteração</label>
          <textarea rows="5" cols="5" class="form-control" style="height: auto;" placeholder="Texto a ser alterado"
            formControlName="texto" [disableControl]='loading["enviando"]'></textarea>
          <show-errors [control]="form.controls.texto"></show-errors>
        </div>
      </div>
    </form>

    <button type="button" (click)="enviar()" hiddenMenu class="btn btn-primary"
      [disabled]='loading["enviando"] || error' *ngIf="!error">
      <app-loading icone="ti ti-new-window" [loading]="loading['enviando'] && !error" texto="Salvar"
        textoLoading="Enviando... Aguarde!"></app-loading>
    </button>

    <button type="button" (click)="cancelar()" hiddenMenu class="btn btn-info"
      [disabled]='loading["enviando"] && !error' *ngIf="!error">
      Cancelar
    </button>

    <div *ngIf="error" class="alert alert-danger">
      <p><strong>Carta de Correção não protocolada ao SEFAZ!</strong></p>
      <p><strong>Motivo: </strong>{{error}}</p>

      <button type="button" (click)="cancelar()" hiddenMenu class="btn btn-mini btn-danger">
        Fechar
      </button>
    </div>
  </div>
</app-modal-basic>