export class TipoCampoCartaCorrecao {
    grupo: string;
    campo: string;
    descricao: string;

    constructor(grupo: string, campo: string, descricao: string) {
        this.grupo = grupo;
        this.campo = campo;
        this.descricao = descricao;
    }

    public static listar(carregaEmBranco: boolean): TipoCampoCartaCorrecao[] {
        let retorno: TipoCampoCartaCorrecao[] = [];

        if (carregaEmBranco) {
            retorno.push(new TipoCampoCartaCorrecao('', '', ''));
        }

        retorno.push(new TipoCampoCartaCorrecao("ide", "cMunFim", "Dados Gerais - Cidade Fim"));
        retorno.push(new TipoCampoCartaCorrecao("compl", "xObs", "Dados Gerais - Observação"));

        retorno.push(new TipoCampoCartaCorrecao("toma4", "xNome", "Dados Outro Tomador - Nome"));
        retorno.push(new TipoCampoCartaCorrecao("enderToma", "xLgr", "Dados Outro Tomador - Endereço"));
        retorno.push(new TipoCampoCartaCorrecao("toma4", "fone", "Dados Outro Tomador - Fone"));

        retorno.push(new TipoCampoCartaCorrecao("rem", "xNome", "Dados Remetente - Nome"));
        retorno.push(new TipoCampoCartaCorrecao("enderReme", "xLgr", "Dados Remetente - Endereço"));
        retorno.push(new TipoCampoCartaCorrecao("rem", "fone", "Dados Remetente - Fone"));

        retorno.push(new TipoCampoCartaCorrecao("exped", "xNome", "Dados Expedidor - Nome"));
        retorno.push(new TipoCampoCartaCorrecao("enderExped", "xLgr", "Dados Expedidor - Endereço"));
        retorno.push(new TipoCampoCartaCorrecao("exped", "fone", "Dados Expedidor - Fone"));

        retorno.push(new TipoCampoCartaCorrecao("receb", "xNome", "Dados Recebedor - Nome"));
        retorno.push(new TipoCampoCartaCorrecao("enderReceb", "xLgr", "Dados Recebedor - Endereço"));
        retorno.push(new TipoCampoCartaCorrecao("receb", "fone", "Dados Recebedor - Fone"));

        retorno.push(new TipoCampoCartaCorrecao("dest", "xNome", "Dados Destinatário - Nome"));
        retorno.push(new TipoCampoCartaCorrecao("enderDest", "xLgr", "Dados Destinatário - Endereço"));
        retorno.push(new TipoCampoCartaCorrecao("dest", "fone", "Dados Destinatário - Fone"));

        retorno.push(new TipoCampoCartaCorrecao("infCarga", "proPred", "Dados Carga - Produto Predominante"));
        retorno.push(new TipoCampoCartaCorrecao("infQ", "cUnid", "Dados Carga - Unidade de Medida"));
        retorno.push(new TipoCampoCartaCorrecao("infQ", "qCarga", "Dados Carga - Quantidade de Carga"));

        retorno.push(new TipoCampoCartaCorrecao("moto", "xNome", "Dados Carga - Motorista"));
        retorno.push(new TipoCampoCartaCorrecao("veic", "placa", "Dados Carga - Veículos"));
        retorno.push(new TipoCampoCartaCorrecao("seg", "xSeg", "Dados Carga - Seguro"));

        return retorno;
    }
}
