export interface DimDatatableColumn {
  label?: string;
  col?: string;
  hide?: boolean;
  limit?: number;
  tooltip?: string | Function;
  style?: Object | Function;
  format?: Function;
  tipo?: string;
  color?: string;
  customTmpl?: boolean;

  date?: boolean;
  currency?: boolean;
  nowrap?: boolean;
}
