<app-modal-basic #modalRelatorio (closed)="destroyComponent()"  [modalContentId]="'modal-vendanfce'">
    <div class="app-modal-header">
      <h4 class="modal-title">Vendas NFC-e</h4>
    </div>
    <div class="app-modal-body">
      <form [formGroup]="impressaoForm" (ngSubmit)="submitForm()">
        <div class="row">
          <div class="form-group col-sm-12">
            <label>Emissão</label>
            <div class="input-group">
              <app-data [calendarPosition]="'left'" formControlName="periodo1"></app-data>
              <span class="input-group-addon">até</span>
              <app-data formControlName="periodo2"></app-data>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12 check-form">
            <div class="checkbox-fade fade-in-default">
              <label>
                <input type="checkbox" formControlName="formaPagamento" >
                <span class="cr">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span>
                <span>Somente o resumo por forma de pagamento</span>
              </label>
            </div>
          </div>
        </div>
        <button type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
          <i class="icofont icofont-print"> </i> Imprimir
        </button>
        <button type="button" class="btn btn-danger ripple" (click)="modalRelatorio.hide()"><i class="ti ti-exit"></i>
          Cancelar
        </button>
      </form>
    </div>
    <div class="app-modal-footer">
    </div>
  </app-modal-basic>