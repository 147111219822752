export interface CalculosPagamentoTitulo {
	id: number;
	numero: number;
	serie: string;
	emissao: Date | null;
	vencimento: Date | null;
	valorParcela: number;
	valorDespesasFinanceiras: number;
	diasVencido: number;
	jurosDiario: number;
	valorJurosDiario: number;
	valorJuros: number;
	valorDescontoPontualidade: number;
	valorDescontoFormaPagamento: number;
	valorDesconto: number;
	valorPagar: number;
	valorAposVencimento: number;
	valorAntesVencimento: number;
	valorTotal: number;
	valorAbatimento: number;
}