<app-modal-basic #modalConsulta dialogClass="modal-lg" (closed)="closed.emit()">
  <div class="app-modal-header">
    <h4 class="modal-title text-center">
      Notas Fiscais de Retorno
    </h4>
  </div>
  <div class="app-modal-body">
    <div class="row mb-2" *ngIf="clienteID">
      <div class="col">
        <div class="checkbox-fade fade-in-default">
          <label>
            <input type="checkbox" [(ngModel)]="exibirTodosDestinatarios" (change)="exibirTodosDestinatariosChanged()">
            <span class="cr">
              <i class="cr-icon icofont icofont-ui-check txt-default"></i>
            </span>
            <span> Exibir notas de todos os destinatários</span>
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <app-dim-datatable *ngIf="notas.items && notas.items.length > 0 || loading['table']" [loading]="loading['table']" [selectable]="true" [selecionados]="selecionados" [columns]="colunas" [data]="notas.items"
        [totalItems]="notas.totalItems" [currentPage]="notas.pageIndex" [rowsOnPage]="10" (sorting)="onSort($event)" (pageChanged)="onSort($event)"></app-dim-datatable>
        <p *ngIf="!(notas.items && notas.items.length > 0 || loading['table'])" class="mt-2 mb-4">Nenhum retorno encontrado</p>
      </div>
    </div>
    <div class="checkbox-fade fade-in-default checkDadosAdicionais">
      <label class="m-b-10">
        <input type="checkbox" [(ngModel)]="buscaDadosAdicionais">
        <span class="cr">
          <i class="cr-icon icofont icofont-ui-check txt-default"></i>
        </span>
        <span>N° do Retorno nos Dados Adicionais</span>
      </label>

      <label>
        <input type="checkbox" [(ngModel)]="retornaNumerosRemessa">
        <span class="cr">
          <i class="cr-icon icofont icofont-ui-check txt-default"></i>
        </span>
        <span>N° da Remessa nos Dados Adicionais</span>
      </label>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button class="btn btn-primary" (click)="carregarItens()" [disabled]="loading['itens'] || selecionados.length === 0">
          <app-loading icone='ti ti-new-window' [loading]="loading['itens']" texto="Carregar itens"></app-loading>
        </button>
        <button class="btn btn-danger" (click)="closed.emit()">
          <i class="ti ti-close"></i> Cancelar
        </button>
      </div>
    </div>
  </div>
</app-modal-basic>
