export interface Representante {
  codigo?: number,
  email?: string,
  endereco?: string,
  id?: number,
  representanteId?: number, // Workaround para enviar RepresentanteID ao salvar um cliente
  inativo?: boolean,
  linhasProdutosVendedores?: any[],
  nome?: string,
  preposto?: boolean,
  prepostoId?: number,
  representantePreposto?: Representante,
  tabelasPrecoRepresentantes?: any[],
  telefone?: string,
  tipoComissao?: any,
  tipoComissaoId?: number,
  unidadeEmpresaId?: number,
  usuario?: any,
  usuarioId?: number,
}

export interface RepresentantePorCliente {
  clienteId?: number,
  id?: number,
  representante?: Representante,
  representanteId?: number,
}
