<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" textHeader="Editar CFOP" modalContentId="modal-editar-cfop-nota-saida" customClass="modal-lg">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-info background-info w-100">
        <p class="mb-0">A <strong>Edição de CFOP</strong> pode ser usada quando houver uma Carta de Correção alterando o CFOP dos itens
          da Nota de Saída. <br />
          Ao <strong>Salvar</strong>, todos os itens da nota de saída possuindo o <strong>CFOP Saída</strong> serão
          ajustados para o <strong>Novo CFOP</strong> selecionado. <br />
          Essa alteração não é visível na impressão da Nota de Saída.
        </p>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <p><strong>Cliente:</strong> {{ clienteCnpj }} - {{ clienteNome }}</p>
      <p><strong>Nota de Saída:</strong> {{ numeroNotaFiscalSaida }}</p>
    </div>
  </div>
  <ng-container *ngIf="loading['cfop']">
    <div class="row flex justify-content-center mt-2">
      <i class="fa fa-circle-o-notch fa-spin fa-lg"></i>
    </div>
  </ng-container>
  <div class="row" *ngFor="let cfopSaida of dados.cfopsNotaSaida">
    <div class="col-6">
      <div class="form-group">
        <label for="form_cfop-saida">CFOP Saída</label>
        <input id="form_cfop-saida" type="text" class="form-control" readonly [value]="cfopSaida.codigo + ' - ' + cfopSaida.descricao"
          [ngbTooltip]="cfopSaida.codigo + ' - ' + cfopSaida.descricao">
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="form_novo-cfop-saida">Novo CFOP</label>
        <ng-select id="form_novo-cfop-saida" placeholder="Não alterar" appendTo="#modal-editar-cfop-nota-saida"
          [items]="dados.cfopsDisponiveis" bindValue="codigo" [searchFn]="buscaCfop"
          [(ngModel)]="cfopSaida.novoCodigo" [loading]="loading['cfop']"
          [disabled]="loading['cfop'] || loading['salvar']" >
          <ng-template ng-label-tmp ng-option-tmp let-item="item">
            <span container="body" [ngbTooltip]="item.codigo + ' - ' + item.descricao">{{item.codigo}} - {{item.descricao}}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <button type="submit" (click)="salvar()" class="btn btn-primary ripple light" [disabled]="loading['cfop'] || loading['salvar'] || !alterouCfop">
        <app-loading [loading]="loading['salvar']" texto="Salvar" textoLoading="Salvando" icone="icofont icofont-save"></app-loading>
      </button>
      <button type="button" class="btn btn-danger ripple" (click)="modalCadastro.hide()" [disabled]="loading['salvar']">
        <i class="ti ti-close"></i> Cancelar
      </button>
    </div>
  </div>
</app-temp-modal-cadastro>