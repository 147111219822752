<label for="" [style.font-weight]="required ? 'bold' : 'none'">
  {{ label }}
</label>
<ng-select [appendTo]="appendTo"
  [items]="situacaoTributariaICMS"
  [loading]="loading"
  [formControl]="stSelected"
  (change)="onComboboxChange($event)"
  [placeholder]="placeholder"
  bindValue="tipo"
  bindLabel="descricao"
>
</ng-select>
