<label
  *ngIf="label"
  [appDimBoldText]="required"
  [for]="id"
  >
  {{label}}
</label>
<!-- De onde eu peguei de exemplo esse dropdown (pedido-fechamento.component)
  esse dropdown está como o parent do input (no momento deste comentário),
  aqui eu fiz ao lado e utilizei o arquivo .scss deste componente pra manter o mesmo visual de lá. -->
<div *ngIf="dropdownOptions?.length > 0" ngbDropdown container="body" class="dim-input-text-dropdown">
  <button ngbDropdownToggle type="button"
          class="btn btn-secondary dropdown-toggle mr-0 dim-input-text-dropdown__button" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">{{ dropdownSelected.placeholder ?? 'err' }}</button>
  <div ngbDropdownMenu class="dropdown-menu dropdown-desconto">
    <a href="javascript:" class="dropdown-item" *ngFor="let option of dropdownOptions" (click)="handleDropdownChange(option)">
      {{ option.label }}
    </a>
  </div>
</div>
<input
  *ngIf="!maskType"
  [id]="id"
  [ngbTooltip]="getNgbTooltip()"
  [ngClass]="getNgClass()"
  class="form-control dim-input-text"
  type="text"
  [formControl]="control"
  (input)="onInputTextChange()"
  [maxLength]="maxLength"
  [placeholder]="placeholder"
  [ngStyle]="inputTextNgStyle()"
  [readonly]="readonly"
/>
<input
  *ngIf="maskType === 'currency' || maskType === 'quantity'"
  [id]="id"
  [ngbTooltip]="getNgbTooltip()"
  [ngClass]="getNgClass()"
  class="form-control dim-input-text"
  type="text"
  [formControl]="control"
  (ngModelChange)="onInputTextChange()"
  [placeholder]="placeholder"
  [ngStyle]="inputTextNgStyle()"
  currencyMask inputmode="numeric"
  [options]="currencyMaskConfig"
  [readonly]="readonly"
/>
<!-- Adicionei esse slot pra ficar mais flexível a configuração de elementos a direita do input.
  Tive que usar class="d-flex" na tag do app-dim-input-text na minha implementação. -->
<ng-content select="[slot=button]"></ng-content>
<show-errors *ngIf="showErrors" [control]="control"></show-errors>
<!-- FIXME esse icon se for usado com button pode ficar zoado. -->
<i
  *ngIf="icon"
  [ngClass]="icon"
  aria-hidden="true"
  style="position: absolute; top: 39px; left: 25px; color: grey;"
></i>
