import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { TipoCampoCartaCorrecao } from '../../../model/TipoCampoCartaCorrecao';
import { ConhecimentoTransporteService } from '../../../services/conhecimento-transporte.service';
import { markFormGroupTouched } from '../../../general/utils';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-enviar-carta-correcao',
  templateUrl: './enviar-carta-correcao.component.html',
  styleUrls: ['./enviar-carta-correcao.component.scss']
})

export class EnviarCartaCorrecaoComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modal', { static: true }) modalConsultar;
  @ViewChild('idCte', { static: true }) idCte;

  listaCampos: TipoCampoCartaCorrecao[] = [];
  cteId: number;
  error: string = '';

  form: FormGroup = new FormGroup({
    alteracao: new FormControl(null, Validators.required),
    texto: new FormControl(null, [Validators.required, Validators.minLength(15)])
  });

  constructor(private cteService: ConhecimentoTransporteService,
    public messageService: MessageService,
  ) {
    super();
  }

  show() {
    this.modalConsultar.show();
  }

  onClose() {
    this.closed.emit();
  }

  enviar() {
    markFormGroupTouched(this.form);
    if (this.form.valid) {
      this.loading['enviando'] = true;
      const formData = this.form.getRawValue();
      const campoSelecionado = formData.alteracao;

      const param = {
        cteId: this.cteId,
        grupo: campoSelecionado.grupo,
        campo: campoSelecionado.campo,
        texto: formData.texto
      };

      this.cteService.cartaCorrecao(param)
        .subscribe((res) => {
          if (res.success) {
            this.messageService.addSuccess({ title: 'Carta de Correção protocolada junto à CT-e!', msg: '', timeout: 15000 });
            this.closed.emit();
          }
          else {
            this.messageService.addError({ title: 'Erro ao gerar Carta de Correção', msg: res.msg, timeout: 30000 });
            this.error = res.msg;
          }
        }, () => {
          this.error = 'Ocorreu um erro ao gerar ao gerar a Carta de Correção!';
          this.messageService.addError({
            title: this.error,
            msg: '',
            showClose: true, timeout: 15000, theme: 'bootstrap', closeOther: true
          });
          this.closed.emit();
        });
    }
  }

  cancelar() {
    this.closed.emit();
  }

  ngOnInit() {
    this.listaCampos = TipoCampoCartaCorrecao.listar(true);
    
    if (this.prop !== null) {
      this.cteId = this.prop.cteId;
      this.form.controls['texto'].setValue(this.prop.desc);

      const campoSelecionado = this.listaCampos.find(
        item => item.grupo === this.prop.grupo && item.campo === this.prop.campo
      );

      if (campoSelecionado) {
        this.form.get('alteracao')?.setValue(campoSelecionado);
      }
    }
  }
}
