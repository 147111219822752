import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from '@microsoft/signalr';
import { MessageService } from '@services';
import { fileToBase64, getErrorMessage } from '@utils';
import { firstValueFrom } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { ParamConsulta } from '../../../general/models/paramConsulta';
import { TipoNfe } from '../../../model/TipoNfe';
import { TipoSituacaoTributariaCOFINS } from '../../../model/TipoSituacaoTributariaCOFINS';
import { ListaTipoSituacaoTributariaICMS, ListaTipoSituacaoTributariaICMSRegimeNormal, ListaTipoSituacaoTributariaICMSSimples, SituacaoTributariaICMS } from '@models';
import { TipoSituacaoTributariaIPI } from '../../../model/TipoSituacaoTributariaIPI';
import { TipoSituacaoTributariaPIS } from '../../../model/TipoSituacaoTributariaPIS';
import { CodigosFiscaisService } from '../../../services/codigos-fiscais.service';
import { ItensService } from '../../../services/itens.service';
import { NotasFiscaisService } from '../../../services/notas-fiscais.service';
import { UnidadesEmpresaParametrosService } from '../../../services/unidades-empresa-parametros.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-notas-fiscais',
  templateUrl: './notas-fiscais.component.html',
  styleUrls: ['./notas-fiscais.component.css']
})
export class NotasFiscaisComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private notasFiscaisService: NotasFiscaisService,
    private unidadeEmpresaParametrosService: UnidadesEmpresaParametrosService,
    private messageService: MessageService,
    private itensService: ItensService,
    private codigosFiscaisService: CodigosFiscaisService,
  ) {
    super();
  }

  @ViewChild('modalConsultar', { static: true }) modalConsultar;
  $searchChanged: Subject<string> = new Subject();
  form: FormGroup;
  notasFiscais;
  notasSelecionadas = [];
  showItensTable = false;
  itens;
  itensSelecionados = [];
  columns = [
    { label: 'Número', col: 'numeroNotaFiscal', hide: false },
    { label: 'Cliente', col: 'cliente', hide: false, limit: 30 },
    { label: 'Emissão', col: 'emissao', date: true, hide: false },
    { label: 'Valor', col: 'valorTotal', hide: false, currency: true },
    { label: 'Quantidade', col: 'quantidade', hide: false },
  ];
  columnsItens = [
    { label: 'NF-e', col: 'numeroNota', invisible: false },
    { label: 'Código', col: 'referenciaItem' },
    { label: 'Produto', col: 'descricaoProduto', customTmpl: true },
    { label: 'Quantidade', col: 'quantidade', customTmpl: true },
    { label: 'Preço', col: 'preco', customTmpl: true },
    { label: 'Valor Total', col: 'valorTotalItem', currency: true },
  ];

  precisaoPreco: number;
  precisaoQtd: number;
  cstICMS: SituacaoTributariaICMS[] = null;
  cstIPI = new TipoSituacaoTributariaIPI().ListarSaida();
  cstPIS = new TipoSituacaoTributariaPIS().List();
  cstCOFINS = new TipoSituacaoTributariaCOFINS().Listar();
  tipoNfeID: number;
  empresaCrt: number;

  currencyMaskOptionsAliquota = {
    prefix: '',
    thousands: '.',
    decimal: ',',
    precision: 2,
    allowNegative: false,
  };

  alterarDadosNcms = null;
  percentualSimples: number | null;
  modo: 'NORMAL' | 'TXT_SEFAZ' = 'NORMAL';
  arquivoSelecionado = null;
  @ViewChild('importarArquivo') importarArquivo;

  ngOnInit() {
    this.tipoNfeID = this.prop?.tipoNfeID;
    this.empresaCrt = this.prop?.empresaCrt;
    this.modo = this.prop?.modo || 'NORMAL';

    this.form = new FormGroup({
      search: new FormControl(null),
      param: new FormControl(new ParamConsulta()),
    });

    if (this.empresaCrt === 1) {
      this.cstICMS = ListaTipoSituacaoTributariaICMSSimples;
    } else {
      this.cstICMS = ListaTipoSituacaoTributariaICMSRegimeNormal;
    }

    this.form.get('search').valueChanges.pipe(debounceTime(800)).subscribe(() => {
      if (!this.loading.consulta) {
        this.consultar(true);
      }
    });

    this.unidadeEmpresaParametrosService.getParametrosNfe().subscribe(params => {
      this.percentualSimples = params.percentualSimples;
    });
    this.unidadeEmpresaParametrosService.getParametrosGerais().subscribe(params => {
      this.precisaoPreco = params.casasDecimaisPreco ? params.casasDecimaisPreco : 2;
      this.precisaoQtd = params.casasDecimaisQuantidade ? params.casasDecimaisQuantidade : 2;
    });

    this.consultar(true);

    if (this.tipoNfeID === TipoNfe.OUTRAS_SAIDAS) {
      this.columnsItens = [
        ...this.columnsItens,
        { label: 'CST/CSOSN ICMS', col: 'situacaoTributariaICMS', customTmpl: true },
        { label: 'Alíq. ICMS', col: 'aliquotaICMS', customTmpl: true },
        { label: 'CST IPI', col: 'situacaoTributariaIPI', customTmpl: true },
        { label: 'Alíq. IPI', col: 'aliquotaIPI', customTmpl: true },
        { label: 'CST PIS', col: 'situacaoTributariaPIS', customTmpl: true },
        { label: 'Alíq. PIS', col: 'aliquotaPIS', customTmpl: true },
        { label: 'CST COFINS', col: 'situacaoTributariaCOFINS', customTmpl: true },
        { label: 'Alíq. COFINS', col: 'aliquotaCOFINS', customTmpl: true },
      ];
    }

    this.alterarDadosNcms = {
      ncms: [],
      codigoFiscalCodigo: null,
      abaterICMSBasePISCOFINS: false,
      somarIPIBaseICMS: false,
      codigoBeneficioFiscal: null,
    };
  }

  get outrasSaidas() {
    return this.tipoNfeID === TipoNfe.OUTRAS_SAIDAS;
  }

  calcularValorTotal(row) {
    if (!row) {
      return;
    }

    row.valorTotalItem = row.quantidade * row.preco;
  }

  show() {
    this.modalConsultar.show();
  }

  onSortNotas(ev) {
    this.form.get('param').setValue(ev);
    this.consultar(false);
  }

  consultar(resetarOrdenacao: boolean) {
    this.loading.consulta = true;

    if (resetarOrdenacao) {
      const param = new ParamConsulta();
      param.alterarOrdenacao('emissao', 'desc');
      this.form.get('param').setValue(param);
    }

    this.notasFiscaisService.listarNotasFiscais(this.form.value)
      .pipe(finalize(() => this.loading.consulta = false))
      .subscribe(res => {
        if (res) {
          this.notasFiscais = res;
        }
      });
  }

  async listarItens() {
    if (this.notasSelecionadas.length <= 0) {
      return;
    }

    try {
      this.loading.listarItens = true;

      const notasIds = this.notasSelecionadas.map(nota => nota.id);
      const res = await firstValueFrom(this.notasFiscaisService.listarNotasFiscaisItens(notasIds));

      this.alterarDadosNcms.ncms = [];
      const itens = [];
      res.forEach(itemNota => {
        const valorTotalItem = (itemNota.preco * itemNota.quantidade);
        if (itemNota?.item?.ncm) {
          const ncmsExistentes = this.alterarDadosNcms.ncms;
          if (!ncmsExistentes.find(x => x.codigoNCM === itemNota.item.ncm)) {
            ncmsExistentes.push({
              codigoNCM: itemNota.item.ncm,
              situacaoTributariaICMS: null,
              aliquotaICMS: null,
              situacaoTributariaIPI: null,
              aliquotaIPI: null,
              situacaoTributariaPIS: null,
              aliquotaPIS: null,
              situacaoTributariaCOFINS: null,
              aliquotaCOFINS: null,
            });
          }
        }

        itens.push({
          id: itemNota.id,
          numeroNota: itemNota.numeroNotaFiscal,
          produto: itemNota.item.id,
          unidadeMedida: itemNota.unidade,
          descricaoProduto: itemNota.item.nome,
          quantidade: itemNota.quantidade,
          preco: itemNota.preco,
          valorDesconto: itemNota.percentualDesconto ? itemNota.percentualDesconto : itemNota.valorDesconto,
          tipoDesconto: itemNota.percentualDesconto ? 1 : 0,
          valorTotalItem: valorTotalItem,
          referenciaItem: itemNota.item.referenciaItem,
          origemMercadoria: itemNota.item.origemMercadoria,
          ncm: itemNota.item.ncm,
          codigoFiscalID: null,
          impostos: this.outrasSaidas ? {
            aliquotaICMSID: itemNota.item.aliquotaicmsid,
            situacaoTributariaICMS: null,
            aliquotaICMS: itemNota.item.aliquotaIcms,
            aliquotaCreditoICMS: this.percentualSimples,
            situacaoTributariaIPI: itemNota.item.ipiSituacaoTributaria,
            aliquotaIPI: itemNota.item.aliquotaipi,
            situacaoTributariaPIS: itemNota.item.pisSituacaoTributaria,
            aliquotaPIS: itemNota.item.pis,
            situacaoTributariaCOFINS: itemNota.item.cofinsSituacaoTributaria,
            aliquotaCOFINS: itemNota.item.cofins,
            abaterICMSBasePISCOFINS: false,
            somarIPIBaseICMS: false,
            valorIIEntrada: itemNota.valorII,
            codigoBeneficioFiscal: null,
          } : null,
        });
      });

      this.alterarDadosNcms.ncms?.sort((a, b) => a.codigoNCM > b.codigoNCM ? 1 : -1);

      this.itens = itens;
      this.showItensTable = true;
      document.querySelector('#modal-importar-nfe .modal-body')?.scroll({ top: 0, behavior: 'smooth' });
    } catch (err) {
      console.error(err);
      this.messageService.error(getErrorMessage(err), 8000);
    } finally {
      this.loading.listarItens = false;
    }
  }

  async finalizar() {
    if (this.outrasSaidas) {
      const dadosValidos = this.itensSelecionados.every(x => this.validaDadosFiscais(x));
      if (!dadosValidos) {
        this.messageService.error('Existem itens selecionados sem informações de CST preenchidas.', 8000);
        return;
      }

      try {
        this.loading.importarItens = true;

        if (this.alterarDadosNcms.codigoFiscalCodigo) {
          const cfopCodigo = this.alterarDadosNcms.codigoFiscalCodigo;
          const cfop = await firstValueFrom(this.codigosFiscaisService.buscaOuCria(cfopCodigo));
          for (const item of this.itens) {
            item.codigoFiscalID = cfop?.id;
          }
        }

        const atualizarItens = this.itensSelecionados.map(item => {
          const imp = item.impostos;
          return {
            id: item.produto,
            situacaoTributariaIPI: imp.situacaoTributariaIPI,
            aliquotaIPI: imp.aliquotaIPI,
            situacaoTributariaPIS: imp.situacaoTributariaPIS,
            aliquotaPIS: imp.aliquotaPIS,
            situacaoTributariaCOFINS: imp.situacaoTributariaCOFINS,
            aliquotaCOFINS: imp.aliquotaCOFINS,
          };
        });
        await firstValueFrom(this.itensService.alterarDadosFiscaisSemSobrescrever({ itens: atualizarItens }));
      } catch (err) {
        console.error(err);
        this.messageService.error(getErrorMessage(err), 8000);
        return;
      } finally {
        this.loading.importarItens = false;
      }
    }

    this.closed.emit({
      notas: this.notasSelecionadas,
      itens: this.itensSelecionados,
    });
  }

  voltar() {
    this.showItensTable = false;
  }

  mudouCFOP(ev) {
    this.alterarDadosNcms.codigoFiscalCodigo = ev?.codigo;
  }

  estiloLinhaItem(row) {
    if (!this.outrasSaidas) {
      return null;
    }

    const selecionado = this.itensSelecionados.find(x => x.id === row.id);
    if (!selecionado) {
      return null;
    }

    return this.validaDadosFiscais(row) ? null : { 'background-color': '#f9b7b7' };
  }

  validaDadosFiscais(row) {
    const imp = row?.impostos;
    const valido = imp && imp.situacaoTributariaICMS && imp.situacaoTributariaIPI
      && imp.situacaoTributariaPIS && imp.situacaoTributariaCOFINS;
    return valido;
  }

  validarAliquotaDigitadaNCM(ncm, campo) {
    if (!ncm) {
      return;
    }

    if (ncm[campo] > 100) {
      ncm[campo] = 100;
    }
  }

  validarAliquotaDigitada(item, campo) {
    if (!item || !item.impostos) {
      return;
    }

    if (item.impostos[campo] > 100) {
      item.impostos[campo] = 100;
    }
  }

  aplicarDados() {
    this.loading.aplicarDados = true;
    setTimeout(() => {
      // Nota de um cliente possui 400 itens, feito em setTimeout só para atualizar o icone de loading.
      const ncms = this.alterarDadosNcms.ncms;

      for (const item of this.itens) {
        const ncm = ncms.find(x => x.codigoNCM === item.ncm);
        if (!ncm) {
          continue;
        }

        if (ncm.situacaoTributariaICMS !== null) {
          item.impostos.situacaoTributariaICMS = ncm.situacaoTributariaICMS;
        }
        if (ncm.aliquotaICMS !== null) {
          item.impostos.aliquotaICMS = ncm.aliquotaICMS;
        }
        if (ncm.situacaoTributariaIPI !== null) {
          item.impostos.situacaoTributariaIPI = ncm.situacaoTributariaIPI;
        }
        if (ncm.aliquotaIPI !== null) {
          item.impostos.aliquotaIPI = ncm.aliquotaIPI;
        }
        if (ncm.situacaoTributariaPIS !== null) {
          item.impostos.situacaoTributariaPIS = ncm.situacaoTributariaPIS;
        }
        if (ncm.aliquotaPIS !== null) {
          item.impostos.aliquotaPIS = ncm.aliquotaPIS;
        }
        if (ncm.situacaoTributariaCOFINS !== null) {
          item.impostos.situacaoTributariaCOFINS = ncm.situacaoTributariaCOFINS;
        }
        if (ncm.aliquotaCOFINS !== null) {
          item.impostos.aliquotaCOFINS = ncm.aliquotaCOFINS;
        }

        item.impostos.abaterICMSBasePISCOFINS = this.alterarDadosNcms.abaterICMSBasePISCOFINS;
        item.impostos.somarIPIBaseICMS = this.alterarDadosNcms.somarIPIBaseICMS;

        item.impostos.codigoBeneficioFiscal = this.alterarDadosNcms.codigoBeneficioFiscal;
      }

      this.messageService.success('Configurações aplicadas nos itens.', 5000);
      this.loading.aplicarDados = false;
    });
  }

  async selectFile(files: FileList) {
    try {
      this.loading.arquivo = true;
      this.arquivoSelecionado = files[0].name;
      const base64 = await fileToBase64(files[0]);

      const res = await firstValueFrom(this.notasFiscaisService.importarTxtSefaz(base64));
      if (!res) {
        this.messageService.error('Falha desconhecida.', 5000);
        return;
      }

      this.alterarDadosNcms.ncms = [];
      const itens = [];
      for (const nota of res.notas) {
        for (const itemNota of nota.itens) {
          if (itemNota?.ncm) {
            const ncmsExistentes = this.alterarDadosNcms.ncms;
            if (!ncmsExistentes.find(x => x.codigoNCM === itemNota.ncm)) {
              ncmsExistentes.push({
                codigoNCM: itemNota.ncm,
                situacaoTributariaICMS: null,
                aliquotaICMS: null,
                situacaoTributariaIPI: null,
                aliquotaIPI: null,
                situacaoTributariaPIS: null,
                aliquotaPIS: null,
                situacaoTributariaCOFINS: null,
                aliquotaCOFINS: null,
              });
            }
          }

          itens.push({
            id: itens.length, // Não existe ID nesse caso, mas precisa de um ID para a seleção no datatable.
            produto: itemNota.itemID,
            unidadeMedida: itemNota.unidade,
            descricaoProduto: itemNota.nome,
            quantidade: itemNota.quantidade,
            preco: itemNota.preco,
            valorDesconto: 0,
            tipoDesconto: 1,
            valorTotalItem: itemNota.quantidade * itemNota.preco,
            referenciaItem: itemNota.referenciaItem,
            origemMercadoria: itemNota.origemMercadoria,
            ncm: itemNota.ncm,
            codigoFiscalID: null,
            impostos: this.outrasSaidas ? {
              aliquotaICMSID: itemNota.aliquotaicmsid,
              situacaoTributariaICMS: null,
              aliquotaICMS: itemNota.aliquotaIcms,
              aliquotaCreditoICMS: this.percentualSimples,
              situacaoTributariaIPI: itemNota.ipiSituacaoTributaria,
              aliquotaIPI: itemNota.aliquotaipi,
              situacaoTributariaPIS: itemNota.pisSituacaoTributaria,
              aliquotaPIS: itemNota.pis,
              situacaoTributariaCOFINS: itemNota.cofinsSituacaoTributaria,
              aliquotaCOFINS: itemNota.cofins,
              abaterICMSBasePISCOFINS: false,
              somarIPIBaseICMS: false,
              valorIIEntrada: itemNota.valorII,
              codigoBeneficioFiscal: null,
            } : null,
          });
        }
      }

      this.itens = itens;
      if (this.columnsItens.find(x => x.col === 'numeroNota')) {
        this.columnsItens.find(x => x.col === 'numeroNota').invisible = true;
      }
      this.showItensTable = true;
    } catch (err) {
      console.error(err);
      this.messageService.error(getErrorMessage(err), 8000);
    } finally {
      this.loading.arquivo = false;
    }
  }

  filePicker(): void {
    (this.importarArquivo.nativeElement.querySelector('.file-input') as HTMLElement).click();
  }

  moverFoco(ev, prefixo: string, coluna: number, linha: number, ultimaColuna: number) {
    // Função criada para suportar movimento entre campos via tab/shift tab/seta para cima/seta para baixo.
    // Também corrige o problema de foco do ng-select, atualmente está jogando foco no 'x' e o shift tab não funciona.
    //   A versão 13.1.1 corrige, mas precisa do angular 18.

    if (ev.target.ariaAutoComplete === 'list' && ev.target?.attributes['aria-controls']?.specified) {
      // Se for ng-select e a lista estiver aberta, continua normalmente.
      return;
    }

    if (ev.key === 'ArrowDown') {
      const campo = document.getElementById(`${prefixo}-${coluna}-${linha + 1}`);
      campo?.focus();
      setTimeout(() => {
        (campo as HTMLInputElement)?.select();
      });
    } else if (ev.key === 'ArrowUp') {
      const campo = document.getElementById(`${prefixo}-${coluna}-${linha - 1}`);
      campo?.focus();
      setTimeout(() => {
        (campo as HTMLInputElement)?.select();
      });
    } else if (ev.key === 'Tab' && ev.shiftKey) {
      // Shift tab precisa mandar para a linha de cima caso seja o primeiro campo.
      if (coluna - 1 >= 0) {
        document.getElementById(`${prefixo}-${coluna - 1}-${linha}`)?.focus();
      } else {
        document.getElementById(`${prefixo}-${ultimaColuna}-${linha - 1}`)?.focus();
      }
      ev.preventDefault();
    } else if (ev.key === 'Tab' && !ev.shiftKey) {
      // Tab precisa mandar para a próxima linha caso seja o primeiro campo.
      if (coluna + 1 <= ultimaColuna) {
        document.getElementById(`${prefixo}-${coluna + 1}-${linha}`)?.focus();
      } else {
        document.getElementById(`${prefixo}-0-${linha + 1}`)?.focus();
      }
      ev.preventDefault();
    }
  }

  desabilitarSetaParaBaixo(ev) {
    if (ev.key === 'ArrowDown' && !ev.target?.attributes['aria-controls']?.specified) {
      // Ignorar seta para baixo se a lista não estiver aberta, para permitir mover o foco para o campo de baixo.
      return false;
    }
    return true;
  }

  get modalClass() {
    if ((this.outrasSaidas || this.modo === 'TXT_SEFAZ') && this.showItensTable) {
      return 'modal-xxl';
    }
    return 'modal-lg';
  }
}
