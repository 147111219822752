import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboboxComponent } from './combobox/combobox.component';
import { FornecedoresService } from '../services/fornecedores.service';
import { SharedModule } from '../shared/shared.module';
import { ComboboxProdutoComponent } from './combobox-produto/combobox-produto.component';
import { ComboboxClienteComponent } from './combobox-cliente/combobox-cliente.component';
import { ClientesService } from '../services/clientes.service';
import { ItensService } from '../services/itens.service';
import { LegendaStatusDocumentosComponent } from './legenda-status-documentos/legenda-status-documentos.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { EventosContabeisService } from '../services/eventos-contabeis.service';
import { ContasContabeisService } from '../services/contas-contabeis.service';
import { HistoricosContabeisPadraoService } from '../services/historicos-contabeis-padrao.service';
import { MotivosMovimentacaoEstoqueService } from '../services/motivo-movimentacao-estoque.service';
import { AlmoxarifadosService } from '../services/almoxarifados.service';
import { UnidadesMedidaService } from '../services/unidades-medida.service';
import { ComboboxTreeviewComponent } from './combobox-treeview/combobox-treeview.component';
import { TreeviewModule } from './combobox-treeview/ngx-treeview/treeview.module';
import { DisabledOnSelectorDirective } from './configs/disabled-on-selector.directive';
import { RepresentantesService } from '../services/representantes.service';
import { CondicoesPagamentoService } from '../services/condicoes-pagamento.service';
import { TipoComissaoService } from '../services/tipo-comissao.service';
import { ComboboxVendedorPorClienteComponent } from './combobox-vendedor-por-cliente/combobox-vendedor-por-cliente.component';
import { ComboboxStatusDocumentoComponent } from './combobox-status-documento/combobox-status-documento.component';
import { ComboboxClienteEnderecoComponent } from './combobox-cliente-endereco/combobox-cliente-endereco.component';
import { ClientesEnderecosService } from '../services/clientes-enderecos.service';
import { AliquotasIcmsService } from '../services/aliquotasIcms.service';
import { CollapseComponent } from './collapse/collapse.component';
import { ValidaAutomacaoComponent } from './valida-automacao/valida-automacao.component';
import { EmailBoxComponent } from './email-box/email-box.component';
import { TipoNfeService } from '../services/tipo-nfe.service';
import { CodigosFiscaisOperacaoService } from '../services/codigos-fiscais-operacao.service';
import { SeriesAlternativasService } from '../services/series-alternativas.service';
import { ItensFornecedoresService } from '../services/itens-fornecedores.service';
import { ObservacoesPadroesService } from '../services/observacoes-padroes.service';
import { TipoItensService } from '../services/tipo-itens.service';
import { IntermediadorService } from '../services/intermediador.service';
import { CodigosFiscaisService } from '../services/codigos-fiscais.service';
import { RegrasTributariasService } from '../services/regras-tributarias.service';
import { AdminService } from '../admin/services/admin.service';
import { BandeiraCartoesService } from '../services/bandeira-cartoes.service';
import { InputSenhaComponent } from './input-senha/input-senha.component';
import { AjusteNumericoComponent } from './ajuste-numerico/ajuste-numerico.component';
import { IntermediadorRecebedorService } from '../services/intermediador-recebedor.service';
import { NotasFiscaisEnderecosService } from '../services/notas-fiscais-enderecos.service';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxMaskConfig } from '../config/NgxMaskConfig';
import { ProcessoImportacaoService } from '../services/processo-importacao.service';
import { ComboboxSituacaoTributariaIcmsComponent } from './combobox-situacao-tributaria-icms/combobox-situacao-tributaria-icms.component';
import { VeiculosService } from '../services/veiculos.service';
import { MotoristasService } from '../services/motoristas.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TreeviewModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    FornecedoresService,
    ItensService,
    ClientesService,
    EventosContabeisService,
    ContasContabeisService,
    HistoricosContabeisPadraoService,
    ClientesService,
    MotivosMovimentacaoEstoqueService,
    AlmoxarifadosService,
    UnidadesMedidaService,
    RepresentantesService,
    CondicoesPagamentoService,
    TipoComissaoService,
    ClientesEnderecosService,
    AliquotasIcmsService,
    TipoNfeService,
    CodigosFiscaisOperacaoService,
    SeriesAlternativasService,
    ItensFornecedoresService,
    ObservacoesPadroesService,
    TipoItensService,
    IntermediadorService,
    CodigosFiscaisService,
    RegrasTributariasService,
    BandeiraCartoesService,
    AdminService,
    IntermediadorRecebedorService,
    NotasFiscaisEnderecosService,
    provideNgxMask(NgxMaskConfig),
    ProcessoImportacaoService,
    VeiculosService,
    MotoristasService,
  ],
  declarations: [
    ComboboxComponent,
    ComboboxProdutoComponent,
    ComboboxClienteComponent,
    LegendaStatusDocumentosComponent,
    ProgressBarComponent,
    ComboboxTreeviewComponent,
    DisabledOnSelectorDirective,
    ComboboxVendedorPorClienteComponent,
    ComboboxStatusDocumentoComponent,
    ComboboxClienteEnderecoComponent,
    CollapseComponent,
    ValidaAutomacaoComponent,
    EmailBoxComponent,
    InputSenhaComponent,
    AjusteNumericoComponent,
    ComboboxSituacaoTributariaIcmsComponent,
  ],
  exports: [
    ComboboxComponent,
    ComboboxProdutoComponent,
    ComboboxClienteComponent,
    LegendaStatusDocumentosComponent,
    ProgressBarComponent,
    ComboboxTreeviewComponent,
    ComboboxVendedorPorClienteComponent,
    ComboboxStatusDocumentoComponent,
    ComboboxClienteEnderecoComponent,
    CollapseComponent,
    ValidaAutomacaoComponent,
    EmailBoxComponent,
    InputSenhaComponent,
    AjusteNumericoComponent,
    ComboboxSituacaoTributariaIcmsComponent,
  ],
})

export class DimComponentesModule { }
